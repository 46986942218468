<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pt-0 pb-3" />
        <v-card>
          <v-data-table
            :headers="headers"
            :items="envios"
            class="elevation-1"
            :search="search"
            :loading="isLoading"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" align="end" v-if="canCreate">
                    <v-btn color="primary" @click="nuevoEnvio" class="to-right">
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="canSee"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="seeEnvio(item)"
                  >
                    {{ seeIcon }}
                  </v-icon>
                </template>
                <span>Ver envío</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="canEdit"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="modifyEnvio(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Modificar envío</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="canDelete"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteEnvio(item.envioConfiguracionId)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar envío</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0 "
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <DeleteDialog
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import enums from "@/utils/enums/index.js";
import PageHeader from "@/components/ui/PageHeader";
import DeleteDialog from "@/components/shared/DeleteDialog";
import { mapActions } from "vuex";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  components: {
    PageHeader,
    DeleteDialog,
    GoBackBtn,
    Ayuda
  },
  name: "EnviosEntidadesCobranzas",
  data() {
    return {
      title: "Envíos a entidades de cobranzas",
      optionCode: enums.webSiteOptions.ENVIOS_ENTIDADES_COBRANZAS,
      showHelp: false,
      showIcon: true,
      showExpand: false,
      envios: [],
      searchIcon: enums.icons.SEARCH,
      seeIcon: enums.icons.SEE,
      deleteIcon: enums.icons.DELETE,
      editIcon: enums.icons.EDIT,
      isLoading: false,
      routeToGo: "FormasPagoYEntidadesCobranza",
      search: "",
      canCreate: false,
      canEdit: false,
      canSee: false,
      canDelete: false,
      titleDelete: "¿Desea eliminarlo?",
      showDeleteModal: false,
      headers: [
        {
          text: "Entidad Facturante",
          align: "start",
          value: "entidadFacturante",
          sortable: false
        },
        {
          text: "Forma de pago",
          align: "start",
          value: "formaDePago",
          sortable: false
        },
        {
          text: "Entidad Financiera",
          align: "start",
          value: "entidadFinanciera",
          sortable: false
        },
        {
          text: "Proceso de envío",
          align: "start",
          value: "procesoEnvio",
          sortable: false
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" }
      ],
      allowedActions: null
    };
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadEnvios();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },

  methods: {
    ...mapActions({
      fetchEnvios: "configuracion/fetchEnvioConfiguracion",
      deleteEnviosConfiguracion: "configuracion/deleteEnviosConfiguracion",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVO_ENVIO_ENTIDAD:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_ENVIO_ENTIDAD:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.VER_ENVIO_ENTIDAD:
            this.canSee = true;
            break;
          case enums.modules.adminSistema.permissions.ELIMINAR_ENVIO_ENTIDAD:
            this.canDelete = true;
            break;
          default:
            break;
        }
      });
    },
    async loadEnvios() {
      const response = await this.fetchEnvios();
      this.envios = response;
    },
    deleteEnvio(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      try {
        const response = await this.deleteEnviosConfiguracion({
          envioConfiguracionId: this.idToDelete
        });

        if (response.status === 200) {
          this.showDeleteModal = false;
          this.setAlert({ type: "success", message: "Eliminado con éxito." });
          this.loadEnvios();
        }
      } catch {
        this.showDeleteModal = false;
        this.loadEnvios();
      }
    },
    nuevoEnvio() {
      this.$router.push({ name: "NuevoEnvioAEntidadCobranza" });
    },
    seeEnvio(item) {
      this.$router.push({
        name: "NuevoEnvioAEntidadCobranza",
        params: { seeEnvioConfig: item.envioConfiguracionId }
      });
    },
    modifyEnvio(item) {
      this.$router.push({
        name: "NuevoEnvioAEntidadCobranza",
        params: { envioConfiguracionId: item.envioConfiguracionId }
      });
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style></style>
